<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.smtp') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('smtp.sender_name') }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" :class="validation && validation.sender_name ? 'is-invalid' : ''" v-model="data.sender_name" :placeholder="$t('smtp.sender_name')"/>
                    <span v-if="validation && validation.sender_name" class="fv-plugins-message-container invalid-feedback">{{ validation.sender_name[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('smtp.email') }}</label>
                  <div class="input-group">
                    <input type="email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''" v-model="data.email" :placeholder="$t('smtp.email')"/>
                    <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">{{ validation.email[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('smtp.main_password') }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" :class="validation && validation.main_password ? 'is-invalid' : ''" v-model="data.main_password" :placeholder="$t('smtp.main_password')"/>
                    <span v-if="validation && validation.main_password" class="fv-plugins-message-container invalid-feedback">{{ validation.main_password[0] }}</span>
                  </div>
                </div>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('smtp.smtp_user_name') }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" :class="validation && validation.smtp_user_name ? 'is-invalid' : ''" v-model="data.smtp_user_name" :placeholder="$t('smtp.smtp_user_name')"/>
                    <span v-if="validation && validation.smtp_user_name" class="fv-plugins-message-container invalid-feedback">{{ validation.smtp_user_name[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('smtp.smtp_service_provider') }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" :class="validation && validation.smtp_service_provider ? 'is-invalid' : ''" v-model="data.smtp_service_provider" :placeholder="$t('smtp.smtp_service_provider')"/>
                    <span v-if="validation && validation.smtp_service_provider" class="fv-plugins-message-container invalid-feedback">{{ validation.smtp_service_provider[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('smtp.smtp_port') }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" :class="validation && validation.smtp_port ? 'is-invalid' : ''" v-model="data.smtp_port" :placeholder="$t('smtp.smtp_port')"/>
                    <span v-if="validation && validation.smtp_port" class="fv-plugins-message-container invalid-feedback">{{ validation.smtp_port[0] }}</span>
                  </div>
                </div>

                <div class="col-lg-6 mb-5">
                  <b-form-checkbox size="lg" v-model="data.ssl_required" name="check-button" switch>{{ $t('smtp.ssl_required') }}</b-form-checkbox>
                </div>
                <div class="col-lg-6 mb-5">
                  <b-form-checkbox size="lg" v-model="data.use_smtp" name="check-button" switch>{{ $t('smtp.use_smtp') }}</b-form-checkbox>
                </div>


            </div>
          </div>
        </div>
      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-smtp",
  data() {
    return {
      mainRoute: 'settings/smtps',
      data: {
        sender_name: null,
        email: null,
        main_password: null,
        smtp_user_name: null,
        smtp_service_provider: null,
        smtp_port: null,
        ssl_required: true,
        use_smtp: true,
      },
      idEdit: null,
      isEditing: false,
      validation: null,
    };
  },

  methods: {
    save() {
      this.data.ssl_required = this.data.ssl_required ? 1 : 0;
      this.data.use_smtp = this.data.use_smtp ? 1 : 0;
      if (this.idEdit) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getData();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    getData() {
      ApiService.get(this.mainRoute + '/get').then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.smtp")}]);
    this.getData();
  },
};
</script>


